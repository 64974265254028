/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { ConsumerDto } from './consumer-dto';
// May contain unused imports in some cases
// @ts-ignore
import type { PointsConversionRequestDto } from './points-conversion-request-dto';

/**
 * 
 * @export
 * @interface MemberDto
 */
export interface MemberDto {
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MemberDto
     */
    'transactionsCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberDto
     */
    'pointsBalance'?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberDto
     */
    'amountSpent'?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    'tierLevelId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    'status'?: MemberDtoStatusEnum;
    /**
     * 
     * @type {ConsumerDto}
     * @memberof MemberDto
     */
    'consumer'?: ConsumerDto;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    'blockReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    'lastTierReset'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    'tierUpgrade'?: string;
    /**
     * 
     * @type {PointsConversionRequestDto}
     * @memberof MemberDto
     */
    'conversionRequest'?: PointsConversionRequestDto;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    'referralCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    'createdWhen'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    'updatedWhen'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    'lastBusinessJoinAsRegular'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum MemberDtoStatusEnum {
    ACTIVE = 'ACTIVE',
    BLOCKED = 'BLOCKED',
    DEPRECATED = 'DEPRECATED',
    FORMER = 'FORMER'
}


