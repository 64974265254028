/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { GetSnapshotByIdAndType200Response } from '../models';
// @ts-ignore
import type { MemberAutoImportRequestInternalDto } from '../models';
// @ts-ignore
import type { MemberAutoImportResponseInternalDto } from '../models';
// @ts-ignore
import type { MemberInternalDto } from '../models';
// @ts-ignore
import type { Pageable } from '../models';
// @ts-ignore
import type { PagedClaimedRewardReportDto } from '../models';
// @ts-ignore
import type { PagedMemberInternalDto } from '../models';
// @ts-ignore
import type { ProblemDetail } from '../models';
// @ts-ignore
import type { TierUsageDto } from '../models';
/**
 * MemberServiceInternalControllerApi - axios parameter creator
 * @export
 */
export const MemberServiceInternalControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MemberAutoImportRequestInternalDto} memberAutoImportRequestInternalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoImportMember: async (memberAutoImportRequestInternalDto: MemberAutoImportRequestInternalDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberAutoImportRequestInternalDto' is not null or undefined
            assertParamExists('autoImportMember', 'memberAutoImportRequestInternalDto', memberAutoImportRequestInternalDto)
            const localVarPath = `/internal/members/auto-import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberAutoImportRequestInternalDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} consumerId 
         * @param {string} pointsSymbol 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalance: async (consumerId: string, pointsSymbol: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'consumerId' is not null or undefined
            assertParamExists('getBalance', 'consumerId', consumerId)
            // verify required parameter 'pointsSymbol' is not null or undefined
            assertParamExists('getBalance', 'pointsSymbol', pointsSymbol)
            const localVarPath = `/internal/consumer/{consumerId}:id/balance/{pointsSymbol}`
                .replace(`{${"consumerId"}}`, encodeURIComponent(String(consumerId)))
                .replace(`{${"pointsSymbol"}}`, encodeURIComponent(String(pointsSymbol)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetCampaignMembersCampaignChannelEnum} campaignChannel 
         * @param {string} businessEntityId 
         * @param {Array<GetCampaignMembersStatusesEnum>} statuses 
         * @param {number} size 
         * @param {Array<GetCampaignMembersGendersEnum>} [genders] 
         * @param {Array<string>} [tierLevels] 
         * @param {number} [ageFrom] 
         * @param {number} [ageTo] 
         * @param {string} [lastMemberId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignMembers: async (campaignChannel: GetCampaignMembersCampaignChannelEnum, businessEntityId: string, statuses: Array<GetCampaignMembersStatusesEnum>, size: number, genders?: Array<GetCampaignMembersGendersEnum>, tierLevels?: Array<string>, ageFrom?: number, ageTo?: number, lastMemberId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignChannel' is not null or undefined
            assertParamExists('getCampaignMembers', 'campaignChannel', campaignChannel)
            // verify required parameter 'businessEntityId' is not null or undefined
            assertParamExists('getCampaignMembers', 'businessEntityId', businessEntityId)
            // verify required parameter 'statuses' is not null or undefined
            assertParamExists('getCampaignMembers', 'statuses', statuses)
            // verify required parameter 'size' is not null or undefined
            assertParamExists('getCampaignMembers', 'size', size)
            const localVarPath = `/internal/campaigns/members`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (campaignChannel !== undefined) {
                localVarQueryParameter['campaignChannel'] = campaignChannel;
            }

            if (businessEntityId !== undefined) {
                localVarQueryParameter['businessEntityId'] = businessEntityId;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = Array.from(statuses);
            }

            if (genders) {
                localVarQueryParameter['genders'] = Array.from(genders);
            }

            if (tierLevels) {
                localVarQueryParameter['tierLevels'] = Array.from(tierLevels);
            }

            if (ageFrom !== undefined) {
                localVarQueryParameter['ageFrom'] = ageFrom;
            }

            if (ageTo !== undefined) {
                localVarQueryParameter['ageTo'] = ageTo;
            }

            if (lastMemberId !== undefined) {
                localVarQueryParameter['lastMemberId'] = lastMemberId;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetCampaignMembersCountCampaignChannelEnum} campaignChannel 
         * @param {string} businessEntityId 
         * @param {Array<GetCampaignMembersCountStatusesEnum>} statuses 
         * @param {Array<GetCampaignMembersCountGendersEnum>} [genders] 
         * @param {Array<string>} [tierLevels] 
         * @param {number} [ageFrom] 
         * @param {number} [ageTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignMembersCount: async (campaignChannel: GetCampaignMembersCountCampaignChannelEnum, businessEntityId: string, statuses: Array<GetCampaignMembersCountStatusesEnum>, genders?: Array<GetCampaignMembersCountGendersEnum>, tierLevels?: Array<string>, ageFrom?: number, ageTo?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignChannel' is not null or undefined
            assertParamExists('getCampaignMembersCount', 'campaignChannel', campaignChannel)
            // verify required parameter 'businessEntityId' is not null or undefined
            assertParamExists('getCampaignMembersCount', 'businessEntityId', businessEntityId)
            // verify required parameter 'statuses' is not null or undefined
            assertParamExists('getCampaignMembersCount', 'statuses', statuses)
            const localVarPath = `/internal/campaigns/members/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (campaignChannel !== undefined) {
                localVarQueryParameter['campaignChannel'] = campaignChannel;
            }

            if (businessEntityId !== undefined) {
                localVarQueryParameter['businessEntityId'] = businessEntityId;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = Array.from(statuses);
            }

            if (genders) {
                localVarQueryParameter['genders'] = Array.from(genders);
            }

            if (tierLevels) {
                localVarQueryParameter['tierLevels'] = Array.from(tierLevels);
            }

            if (ageFrom !== undefined) {
                localVarQueryParameter['ageFrom'] = ageFrom;
            }

            if (ageTo !== undefined) {
                localVarQueryParameter['ageTo'] = ageTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClaimedRewardsCount: async (memberId: string, from?: string, to?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('getClaimedRewardsCount', 'memberId', memberId)
            const localVarPath = `/internal/claimed-rewards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} rewardId 
         * @param {Pageable} pageable 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClaimedRewardsReport: async (businessId: string, rewardId: string, pageable: Pageable, from?: string, to?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getClaimedRewardsReport', 'businessId', businessId)
            // verify required parameter 'rewardId' is not null or undefined
            assertParamExists('getClaimedRewardsReport', 'rewardId', rewardId)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getClaimedRewardsReport', 'pageable', pageable)
            const localVarPath = `/internal/claimed-rewards/{businessId}/{rewardId}`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)))
                .replace(`{${"rewardId"}}`, encodeURIComponent(String(rewardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cognitoEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsumerIdByCognitoEmail: async (cognitoEmail: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cognitoEmail' is not null or undefined
            assertParamExists('getConsumerIdByCognitoEmail', 'cognitoEmail', cognitoEmail)
            const localVarPath = `/internal/consumers/{cognitoEmail}:cognito-email/id`
                .replace(`{${"cognitoEmail"}}`, encodeURIComponent(String(cognitoEmail)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {string} consumerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberIdByBusinessEntityIdAndConsumerId: async (businessEntityId: string, consumerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessEntityId' is not null or undefined
            assertParamExists('getMemberIdByBusinessEntityIdAndConsumerId', 'businessEntityId', businessEntityId)
            // verify required parameter 'consumerId' is not null or undefined
            assertParamExists('getMemberIdByBusinessEntityIdAndConsumerId', 'consumerId', consumerId)
            const localVarPath = `/internal/members/{businessEntityId}:businessEntityId/{consumerId}:consumerId/id`
                .replace(`{${"businessEntityId"}}`, encodeURIComponent(String(businessEntityId)))
                .replace(`{${"consumerId"}}`, encodeURIComponent(String(consumerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [consumerId] 
         * @param {Array<string>} [memberIds] 
         * @param {string} [businessEntityId] 
         * @param {Array<GetMembers1StatusesEnum>} [statuses] 
         * @param {string} [search] 
         * @param {string} [lastSignInFrom] 
         * @param {string} [lastSignInTo] 
         * @param {boolean} [ghostAccount] 
         * @param {boolean} [withBalances] 
         * @param {string} [pointsSymbol] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembers1: async (consumerId?: string, memberIds?: Array<string>, businessEntityId?: string, statuses?: Array<GetMembers1StatusesEnum>, search?: string, lastSignInFrom?: string, lastSignInTo?: string, ghostAccount?: boolean, withBalances?: boolean, pointsSymbol?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/internal/members`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (consumerId !== undefined) {
                localVarQueryParameter['consumerId'] = consumerId;
            }

            if (memberIds) {
                localVarQueryParameter['memberIds'] = memberIds;
            }

            if (businessEntityId !== undefined) {
                localVarQueryParameter['businessEntityId'] = businessEntityId;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (lastSignInFrom !== undefined) {
                localVarQueryParameter['lastSignInFrom'] = (lastSignInFrom as any instanceof Date) ?
                    (lastSignInFrom as any).toISOString() :
                    lastSignInFrom;
            }

            if (lastSignInTo !== undefined) {
                localVarQueryParameter['lastSignInTo'] = (lastSignInTo as any instanceof Date) ?
                    (lastSignInTo as any).toISOString() :
                    lastSignInTo;
            }

            if (ghostAccount !== undefined) {
                localVarQueryParameter['ghostAccount'] = ghostAccount;
            }

            if (withBalances !== undefined) {
                localVarQueryParameter['withBalances'] = withBalances;
            }

            if (pointsSymbol !== undefined) {
                localVarQueryParameter['pointsSymbol'] = pointsSymbol;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [consumerId] 
         * @param {Array<string>} [memberIds] 
         * @param {string} [businessEntityId] 
         * @param {Array<GetMembersPagedStatusesEnum>} [statuses] 
         * @param {string} [search] 
         * @param {string} [lastSignInFrom] 
         * @param {string} [lastSignInTo] 
         * @param {boolean} [ghostAccount] 
         * @param {boolean} [withBalances] 
         * @param {string} [pointsSymbol] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembersPaged: async (consumerId?: string, memberIds?: Array<string>, businessEntityId?: string, statuses?: Array<GetMembersPagedStatusesEnum>, search?: string, lastSignInFrom?: string, lastSignInTo?: string, ghostAccount?: boolean, withBalances?: boolean, pointsSymbol?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/internal/members/paged`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (consumerId !== undefined) {
                localVarQueryParameter['consumerId'] = consumerId;
            }

            if (memberIds) {
                localVarQueryParameter['memberIds'] = memberIds;
            }

            if (businessEntityId !== undefined) {
                localVarQueryParameter['businessEntityId'] = businessEntityId;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (lastSignInFrom !== undefined) {
                localVarQueryParameter['lastSignInFrom'] = (lastSignInFrom as any instanceof Date) ?
                    (lastSignInFrom as any).toISOString() :
                    lastSignInFrom;
            }

            if (lastSignInTo !== undefined) {
                localVarQueryParameter['lastSignInTo'] = (lastSignInTo as any instanceof Date) ?
                    (lastSignInTo as any).toISOString() :
                    lastSignInTo;
            }

            if (ghostAccount !== undefined) {
                localVarQueryParameter['ghostAccount'] = ghostAccount;
            }

            if (withBalances !== undefined) {
                localVarQueryParameter['withBalances'] = withBalances;
            }

            if (pointsSymbol !== undefined) {
                localVarQueryParameter['pointsSymbol'] = pointsSymbol;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {GetSnapshotByIdAndTypeTypeEnum} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshotByIdAndType: async (id: string, type: GetSnapshotByIdAndTypeTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSnapshotByIdAndType', 'id', id)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getSnapshotByIdAndType', 'type', type)
            const localVarPath = `/internal/snapshot/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTiersUsage: async (businessEntityId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessEntityId' is not null or undefined
            assertParamExists('getTiersUsage', 'businessEntityId', businessEntityId)
            const localVarPath = `/internal/business-entities/{businessEntityId}/tiers-usage`
                .replace(`{${"businessEntityId"}}`, encodeURIComponent(String(businessEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MemberServiceInternalControllerApi - functional programming interface
 * @export
 */
export const MemberServiceInternalControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MemberServiceInternalControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MemberAutoImportRequestInternalDto} memberAutoImportRequestInternalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoImportMember(memberAutoImportRequestInternalDto: MemberAutoImportRequestInternalDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberAutoImportResponseInternalDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoImportMember(memberAutoImportRequestInternalDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberServiceInternalControllerApi.autoImportMember']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} consumerId 
         * @param {string} pointsSymbol 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBalance(consumerId: string, pointsSymbol: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBalance(consumerId, pointsSymbol, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberServiceInternalControllerApi.getBalance']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetCampaignMembersCampaignChannelEnum} campaignChannel 
         * @param {string} businessEntityId 
         * @param {Array<GetCampaignMembersStatusesEnum>} statuses 
         * @param {number} size 
         * @param {Array<GetCampaignMembersGendersEnum>} [genders] 
         * @param {Array<string>} [tierLevels] 
         * @param {number} [ageFrom] 
         * @param {number} [ageTo] 
         * @param {string} [lastMemberId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaignMembers(campaignChannel: GetCampaignMembersCampaignChannelEnum, businessEntityId: string, statuses: Array<GetCampaignMembersStatusesEnum>, size: number, genders?: Array<GetCampaignMembersGendersEnum>, tierLevels?: Array<string>, ageFrom?: number, ageTo?: number, lastMemberId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemberInternalDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCampaignMembers(campaignChannel, businessEntityId, statuses, size, genders, tierLevels, ageFrom, ageTo, lastMemberId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberServiceInternalControllerApi.getCampaignMembers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetCampaignMembersCountCampaignChannelEnum} campaignChannel 
         * @param {string} businessEntityId 
         * @param {Array<GetCampaignMembersCountStatusesEnum>} statuses 
         * @param {Array<GetCampaignMembersCountGendersEnum>} [genders] 
         * @param {Array<string>} [tierLevels] 
         * @param {number} [ageFrom] 
         * @param {number} [ageTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaignMembersCount(campaignChannel: GetCampaignMembersCountCampaignChannelEnum, businessEntityId: string, statuses: Array<GetCampaignMembersCountStatusesEnum>, genders?: Array<GetCampaignMembersCountGendersEnum>, tierLevels?: Array<string>, ageFrom?: number, ageTo?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCampaignMembersCount(campaignChannel, businessEntityId, statuses, genders, tierLevels, ageFrom, ageTo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberServiceInternalControllerApi.getCampaignMembersCount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} memberId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClaimedRewardsCount(memberId: string, from?: string, to?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClaimedRewardsCount(memberId, from, to, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberServiceInternalControllerApi.getClaimedRewardsCount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} rewardId 
         * @param {Pageable} pageable 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClaimedRewardsReport(businessId: string, rewardId: string, pageable: Pageable, from?: string, to?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedClaimedRewardReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClaimedRewardsReport(businessId, rewardId, pageable, from, to, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberServiceInternalControllerApi.getClaimedRewardsReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cognitoEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConsumerIdByCognitoEmail(cognitoEmail: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConsumerIdByCognitoEmail(cognitoEmail, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberServiceInternalControllerApi.getConsumerIdByCognitoEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {string} consumerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberIdByBusinessEntityIdAndConsumerId(businessEntityId: string, consumerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMemberIdByBusinessEntityIdAndConsumerId(businessEntityId, consumerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberServiceInternalControllerApi.getMemberIdByBusinessEntityIdAndConsumerId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [consumerId] 
         * @param {Array<string>} [memberIds] 
         * @param {string} [businessEntityId] 
         * @param {Array<GetMembers1StatusesEnum>} [statuses] 
         * @param {string} [search] 
         * @param {string} [lastSignInFrom] 
         * @param {string} [lastSignInTo] 
         * @param {boolean} [ghostAccount] 
         * @param {boolean} [withBalances] 
         * @param {string} [pointsSymbol] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMembers1(consumerId?: string, memberIds?: Array<string>, businessEntityId?: string, statuses?: Array<GetMembers1StatusesEnum>, search?: string, lastSignInFrom?: string, lastSignInTo?: string, ghostAccount?: boolean, withBalances?: boolean, pointsSymbol?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemberInternalDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMembers1(consumerId, memberIds, businessEntityId, statuses, search, lastSignInFrom, lastSignInTo, ghostAccount, withBalances, pointsSymbol, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberServiceInternalControllerApi.getMembers1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [consumerId] 
         * @param {Array<string>} [memberIds] 
         * @param {string} [businessEntityId] 
         * @param {Array<GetMembersPagedStatusesEnum>} [statuses] 
         * @param {string} [search] 
         * @param {string} [lastSignInFrom] 
         * @param {string} [lastSignInTo] 
         * @param {boolean} [ghostAccount] 
         * @param {boolean} [withBalances] 
         * @param {string} [pointsSymbol] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMembersPaged(consumerId?: string, memberIds?: Array<string>, businessEntityId?: string, statuses?: Array<GetMembersPagedStatusesEnum>, search?: string, lastSignInFrom?: string, lastSignInTo?: string, ghostAccount?: boolean, withBalances?: boolean, pointsSymbol?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedMemberInternalDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMembersPaged(consumerId, memberIds, businessEntityId, statuses, search, lastSignInFrom, lastSignInTo, ghostAccount, withBalances, pointsSymbol, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberServiceInternalControllerApi.getMembersPaged']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {GetSnapshotByIdAndTypeTypeEnum} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSnapshotByIdAndType(id: string, type: GetSnapshotByIdAndTypeTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSnapshotByIdAndType200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSnapshotByIdAndType(id, type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberServiceInternalControllerApi.getSnapshotByIdAndType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTiersUsage(businessEntityId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TierUsageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTiersUsage(businessEntityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberServiceInternalControllerApi.getTiersUsage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MemberServiceInternalControllerApi - factory interface
 * @export
 */
export const MemberServiceInternalControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MemberServiceInternalControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {MemberServiceInternalControllerApiAutoImportMemberRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoImportMember(requestParameters: MemberServiceInternalControllerApiAutoImportMemberRequest, options?: RawAxiosRequestConfig): AxiosPromise<MemberAutoImportResponseInternalDto> {
            return localVarFp.autoImportMember(requestParameters.memberAutoImportRequestInternalDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberServiceInternalControllerApiGetBalanceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalance(requestParameters: MemberServiceInternalControllerApiGetBalanceRequest, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.getBalance(requestParameters.consumerId, requestParameters.pointsSymbol, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberServiceInternalControllerApiGetCampaignMembersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignMembers(requestParameters: MemberServiceInternalControllerApiGetCampaignMembersRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<MemberInternalDto>> {
            return localVarFp.getCampaignMembers(requestParameters.campaignChannel, requestParameters.businessEntityId, requestParameters.statuses, requestParameters.size, requestParameters.genders, requestParameters.tierLevels, requestParameters.ageFrom, requestParameters.ageTo, requestParameters.lastMemberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberServiceInternalControllerApiGetCampaignMembersCountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignMembersCount(requestParameters: MemberServiceInternalControllerApiGetCampaignMembersCountRequest, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.getCampaignMembersCount(requestParameters.campaignChannel, requestParameters.businessEntityId, requestParameters.statuses, requestParameters.genders, requestParameters.tierLevels, requestParameters.ageFrom, requestParameters.ageTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberServiceInternalControllerApiGetClaimedRewardsCountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClaimedRewardsCount(requestParameters: MemberServiceInternalControllerApiGetClaimedRewardsCountRequest, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.getClaimedRewardsCount(requestParameters.memberId, requestParameters.from, requestParameters.to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberServiceInternalControllerApiGetClaimedRewardsReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClaimedRewardsReport(requestParameters: MemberServiceInternalControllerApiGetClaimedRewardsReportRequest, options?: RawAxiosRequestConfig): AxiosPromise<PagedClaimedRewardReportDto> {
            return localVarFp.getClaimedRewardsReport(requestParameters.businessId, requestParameters.rewardId, requestParameters.pageable, requestParameters.from, requestParameters.to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberServiceInternalControllerApiGetConsumerIdByCognitoEmailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsumerIdByCognitoEmail(requestParameters: MemberServiceInternalControllerApiGetConsumerIdByCognitoEmailRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getConsumerIdByCognitoEmail(requestParameters.cognitoEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberServiceInternalControllerApiGetMemberIdByBusinessEntityIdAndConsumerIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberIdByBusinessEntityIdAndConsumerId(requestParameters: MemberServiceInternalControllerApiGetMemberIdByBusinessEntityIdAndConsumerIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getMemberIdByBusinessEntityIdAndConsumerId(requestParameters.businessEntityId, requestParameters.consumerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberServiceInternalControllerApiGetMembers1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembers1(requestParameters: MemberServiceInternalControllerApiGetMembers1Request = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<MemberInternalDto>> {
            return localVarFp.getMembers1(requestParameters.consumerId, requestParameters.memberIds, requestParameters.businessEntityId, requestParameters.statuses, requestParameters.search, requestParameters.lastSignInFrom, requestParameters.lastSignInTo, requestParameters.ghostAccount, requestParameters.withBalances, requestParameters.pointsSymbol, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberServiceInternalControllerApiGetMembersPagedRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembersPaged(requestParameters: MemberServiceInternalControllerApiGetMembersPagedRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PagedMemberInternalDto> {
            return localVarFp.getMembersPaged(requestParameters.consumerId, requestParameters.memberIds, requestParameters.businessEntityId, requestParameters.statuses, requestParameters.search, requestParameters.lastSignInFrom, requestParameters.lastSignInTo, requestParameters.ghostAccount, requestParameters.withBalances, requestParameters.pointsSymbol, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberServiceInternalControllerApiGetSnapshotByIdAndTypeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshotByIdAndType(requestParameters: MemberServiceInternalControllerApiGetSnapshotByIdAndTypeRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetSnapshotByIdAndType200Response> {
            return localVarFp.getSnapshotByIdAndType(requestParameters.id, requestParameters.type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberServiceInternalControllerApiGetTiersUsageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTiersUsage(requestParameters: MemberServiceInternalControllerApiGetTiersUsageRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<TierUsageDto>> {
            return localVarFp.getTiersUsage(requestParameters.businessEntityId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for autoImportMember operation in MemberServiceInternalControllerApi.
 * @export
 * @interface MemberServiceInternalControllerApiAutoImportMemberRequest
 */
export interface MemberServiceInternalControllerApiAutoImportMemberRequest {
    /**
     * 
     * @type {MemberAutoImportRequestInternalDto}
     * @memberof MemberServiceInternalControllerApiAutoImportMember
     */
    readonly memberAutoImportRequestInternalDto: MemberAutoImportRequestInternalDto
}

/**
 * Request parameters for getBalance operation in MemberServiceInternalControllerApi.
 * @export
 * @interface MemberServiceInternalControllerApiGetBalanceRequest
 */
export interface MemberServiceInternalControllerApiGetBalanceRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberServiceInternalControllerApiGetBalance
     */
    readonly consumerId: string

    /**
     * 
     * @type {string}
     * @memberof MemberServiceInternalControllerApiGetBalance
     */
    readonly pointsSymbol: string
}

/**
 * Request parameters for getCampaignMembers operation in MemberServiceInternalControllerApi.
 * @export
 * @interface MemberServiceInternalControllerApiGetCampaignMembersRequest
 */
export interface MemberServiceInternalControllerApiGetCampaignMembersRequest {
    /**
     * 
     * @type {'SMS' | 'EMAIL'}
     * @memberof MemberServiceInternalControllerApiGetCampaignMembers
     */
    readonly campaignChannel: GetCampaignMembersCampaignChannelEnum

    /**
     * 
     * @type {string}
     * @memberof MemberServiceInternalControllerApiGetCampaignMembers
     */
    readonly businessEntityId: string

    /**
     * 
     * @type {Array<'MEMBER' | 'GUEST' | 'BLOCKED'>}
     * @memberof MemberServiceInternalControllerApiGetCampaignMembers
     */
    readonly statuses: Array<GetCampaignMembersStatusesEnum>

    /**
     * 
     * @type {number}
     * @memberof MemberServiceInternalControllerApiGetCampaignMembers
     */
    readonly size: number

    /**
     * 
     * @type {Array<'MALE' | 'FEMALE' | 'OTHER'>}
     * @memberof MemberServiceInternalControllerApiGetCampaignMembers
     */
    readonly genders?: Array<GetCampaignMembersGendersEnum>

    /**
     * 
     * @type {Array<string>}
     * @memberof MemberServiceInternalControllerApiGetCampaignMembers
     */
    readonly tierLevels?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof MemberServiceInternalControllerApiGetCampaignMembers
     */
    readonly ageFrom?: number

    /**
     * 
     * @type {number}
     * @memberof MemberServiceInternalControllerApiGetCampaignMembers
     */
    readonly ageTo?: number

    /**
     * 
     * @type {string}
     * @memberof MemberServiceInternalControllerApiGetCampaignMembers
     */
    readonly lastMemberId?: string
}

/**
 * Request parameters for getCampaignMembersCount operation in MemberServiceInternalControllerApi.
 * @export
 * @interface MemberServiceInternalControllerApiGetCampaignMembersCountRequest
 */
export interface MemberServiceInternalControllerApiGetCampaignMembersCountRequest {
    /**
     * 
     * @type {'SMS' | 'EMAIL'}
     * @memberof MemberServiceInternalControllerApiGetCampaignMembersCount
     */
    readonly campaignChannel: GetCampaignMembersCountCampaignChannelEnum

    /**
     * 
     * @type {string}
     * @memberof MemberServiceInternalControllerApiGetCampaignMembersCount
     */
    readonly businessEntityId: string

    /**
     * 
     * @type {Array<'MEMBER' | 'GUEST' | 'BLOCKED'>}
     * @memberof MemberServiceInternalControllerApiGetCampaignMembersCount
     */
    readonly statuses: Array<GetCampaignMembersCountStatusesEnum>

    /**
     * 
     * @type {Array<'MALE' | 'FEMALE' | 'OTHER'>}
     * @memberof MemberServiceInternalControllerApiGetCampaignMembersCount
     */
    readonly genders?: Array<GetCampaignMembersCountGendersEnum>

    /**
     * 
     * @type {Array<string>}
     * @memberof MemberServiceInternalControllerApiGetCampaignMembersCount
     */
    readonly tierLevels?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof MemberServiceInternalControllerApiGetCampaignMembersCount
     */
    readonly ageFrom?: number

    /**
     * 
     * @type {number}
     * @memberof MemberServiceInternalControllerApiGetCampaignMembersCount
     */
    readonly ageTo?: number
}

/**
 * Request parameters for getClaimedRewardsCount operation in MemberServiceInternalControllerApi.
 * @export
 * @interface MemberServiceInternalControllerApiGetClaimedRewardsCountRequest
 */
export interface MemberServiceInternalControllerApiGetClaimedRewardsCountRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberServiceInternalControllerApiGetClaimedRewardsCount
     */
    readonly memberId: string

    /**
     * 
     * @type {string}
     * @memberof MemberServiceInternalControllerApiGetClaimedRewardsCount
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof MemberServiceInternalControllerApiGetClaimedRewardsCount
     */
    readonly to?: string
}

/**
 * Request parameters for getClaimedRewardsReport operation in MemberServiceInternalControllerApi.
 * @export
 * @interface MemberServiceInternalControllerApiGetClaimedRewardsReportRequest
 */
export interface MemberServiceInternalControllerApiGetClaimedRewardsReportRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberServiceInternalControllerApiGetClaimedRewardsReport
     */
    readonly businessId: string

    /**
     * 
     * @type {string}
     * @memberof MemberServiceInternalControllerApiGetClaimedRewardsReport
     */
    readonly rewardId: string

    /**
     * 
     * @type {Pageable}
     * @memberof MemberServiceInternalControllerApiGetClaimedRewardsReport
     */
    readonly pageable: Pageable

    /**
     * 
     * @type {string}
     * @memberof MemberServiceInternalControllerApiGetClaimedRewardsReport
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof MemberServiceInternalControllerApiGetClaimedRewardsReport
     */
    readonly to?: string
}

/**
 * Request parameters for getConsumerIdByCognitoEmail operation in MemberServiceInternalControllerApi.
 * @export
 * @interface MemberServiceInternalControllerApiGetConsumerIdByCognitoEmailRequest
 */
export interface MemberServiceInternalControllerApiGetConsumerIdByCognitoEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberServiceInternalControllerApiGetConsumerIdByCognitoEmail
     */
    readonly cognitoEmail: string
}

/**
 * Request parameters for getMemberIdByBusinessEntityIdAndConsumerId operation in MemberServiceInternalControllerApi.
 * @export
 * @interface MemberServiceInternalControllerApiGetMemberIdByBusinessEntityIdAndConsumerIdRequest
 */
export interface MemberServiceInternalControllerApiGetMemberIdByBusinessEntityIdAndConsumerIdRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberServiceInternalControllerApiGetMemberIdByBusinessEntityIdAndConsumerId
     */
    readonly businessEntityId: string

    /**
     * 
     * @type {string}
     * @memberof MemberServiceInternalControllerApiGetMemberIdByBusinessEntityIdAndConsumerId
     */
    readonly consumerId: string
}

/**
 * Request parameters for getMembers1 operation in MemberServiceInternalControllerApi.
 * @export
 * @interface MemberServiceInternalControllerApiGetMembers1Request
 */
export interface MemberServiceInternalControllerApiGetMembers1Request {
    /**
     * 
     * @type {string}
     * @memberof MemberServiceInternalControllerApiGetMembers1
     */
    readonly consumerId?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof MemberServiceInternalControllerApiGetMembers1
     */
    readonly memberIds?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof MemberServiceInternalControllerApiGetMembers1
     */
    readonly businessEntityId?: string

    /**
     * 
     * @type {Array<'ACTIVE' | 'BLOCKED' | 'DEPRECATED' | 'FORMER'>}
     * @memberof MemberServiceInternalControllerApiGetMembers1
     */
    readonly statuses?: Array<GetMembers1StatusesEnum>

    /**
     * 
     * @type {string}
     * @memberof MemberServiceInternalControllerApiGetMembers1
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof MemberServiceInternalControllerApiGetMembers1
     */
    readonly lastSignInFrom?: string

    /**
     * 
     * @type {string}
     * @memberof MemberServiceInternalControllerApiGetMembers1
     */
    readonly lastSignInTo?: string

    /**
     * 
     * @type {boolean}
     * @memberof MemberServiceInternalControllerApiGetMembers1
     */
    readonly ghostAccount?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MemberServiceInternalControllerApiGetMembers1
     */
    readonly withBalances?: boolean

    /**
     * 
     * @type {string}
     * @memberof MemberServiceInternalControllerApiGetMembers1
     */
    readonly pointsSymbol?: string
}

/**
 * Request parameters for getMembersPaged operation in MemberServiceInternalControllerApi.
 * @export
 * @interface MemberServiceInternalControllerApiGetMembersPagedRequest
 */
export interface MemberServiceInternalControllerApiGetMembersPagedRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberServiceInternalControllerApiGetMembersPaged
     */
    readonly consumerId?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof MemberServiceInternalControllerApiGetMembersPaged
     */
    readonly memberIds?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof MemberServiceInternalControllerApiGetMembersPaged
     */
    readonly businessEntityId?: string

    /**
     * 
     * @type {Array<'ACTIVE' | 'BLOCKED' | 'DEPRECATED' | 'FORMER'>}
     * @memberof MemberServiceInternalControllerApiGetMembersPaged
     */
    readonly statuses?: Array<GetMembersPagedStatusesEnum>

    /**
     * 
     * @type {string}
     * @memberof MemberServiceInternalControllerApiGetMembersPaged
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof MemberServiceInternalControllerApiGetMembersPaged
     */
    readonly lastSignInFrom?: string

    /**
     * 
     * @type {string}
     * @memberof MemberServiceInternalControllerApiGetMembersPaged
     */
    readonly lastSignInTo?: string

    /**
     * 
     * @type {boolean}
     * @memberof MemberServiceInternalControllerApiGetMembersPaged
     */
    readonly ghostAccount?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MemberServiceInternalControllerApiGetMembersPaged
     */
    readonly withBalances?: boolean

    /**
     * 
     * @type {string}
     * @memberof MemberServiceInternalControllerApiGetMembersPaged
     */
    readonly pointsSymbol?: string

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof MemberServiceInternalControllerApiGetMembersPaged
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof MemberServiceInternalControllerApiGetMembersPaged
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof MemberServiceInternalControllerApiGetMembersPaged
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getSnapshotByIdAndType operation in MemberServiceInternalControllerApi.
 * @export
 * @interface MemberServiceInternalControllerApiGetSnapshotByIdAndTypeRequest
 */
export interface MemberServiceInternalControllerApiGetSnapshotByIdAndTypeRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberServiceInternalControllerApiGetSnapshotByIdAndType
     */
    readonly id: string

    /**
     * 
     * @type {'BUSINESS_ENTITY' | 'BUSINESS_USER' | 'MEMBER' | 'OFFER' | 'TIER_CONFIGURATION' | 'REFERRAL_PROGRAM' | 'CONSUMER' | 'REWARD'}
     * @memberof MemberServiceInternalControllerApiGetSnapshotByIdAndType
     */
    readonly type: GetSnapshotByIdAndTypeTypeEnum
}

/**
 * Request parameters for getTiersUsage operation in MemberServiceInternalControllerApi.
 * @export
 * @interface MemberServiceInternalControllerApiGetTiersUsageRequest
 */
export interface MemberServiceInternalControllerApiGetTiersUsageRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberServiceInternalControllerApiGetTiersUsage
     */
    readonly businessEntityId: string
}

/**
 * MemberServiceInternalControllerApi - object-oriented interface
 * @export
 * @class MemberServiceInternalControllerApi
 * @extends {BaseAPI}
 */
export class MemberServiceInternalControllerApi extends BaseAPI {
    /**
     * 
     * @param {MemberServiceInternalControllerApiAutoImportMemberRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberServiceInternalControllerApi
     */
    public autoImportMember(requestParameters: MemberServiceInternalControllerApiAutoImportMemberRequest, options?: RawAxiosRequestConfig) {
        return MemberServiceInternalControllerApiFp(this.configuration).autoImportMember(requestParameters.memberAutoImportRequestInternalDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberServiceInternalControllerApiGetBalanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberServiceInternalControllerApi
     */
    public getBalance(requestParameters: MemberServiceInternalControllerApiGetBalanceRequest, options?: RawAxiosRequestConfig) {
        return MemberServiceInternalControllerApiFp(this.configuration).getBalance(requestParameters.consumerId, requestParameters.pointsSymbol, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberServiceInternalControllerApiGetCampaignMembersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberServiceInternalControllerApi
     */
    public getCampaignMembers(requestParameters: MemberServiceInternalControllerApiGetCampaignMembersRequest, options?: RawAxiosRequestConfig) {
        return MemberServiceInternalControllerApiFp(this.configuration).getCampaignMembers(requestParameters.campaignChannel, requestParameters.businessEntityId, requestParameters.statuses, requestParameters.size, requestParameters.genders, requestParameters.tierLevels, requestParameters.ageFrom, requestParameters.ageTo, requestParameters.lastMemberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberServiceInternalControllerApiGetCampaignMembersCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberServiceInternalControllerApi
     */
    public getCampaignMembersCount(requestParameters: MemberServiceInternalControllerApiGetCampaignMembersCountRequest, options?: RawAxiosRequestConfig) {
        return MemberServiceInternalControllerApiFp(this.configuration).getCampaignMembersCount(requestParameters.campaignChannel, requestParameters.businessEntityId, requestParameters.statuses, requestParameters.genders, requestParameters.tierLevels, requestParameters.ageFrom, requestParameters.ageTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberServiceInternalControllerApiGetClaimedRewardsCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberServiceInternalControllerApi
     */
    public getClaimedRewardsCount(requestParameters: MemberServiceInternalControllerApiGetClaimedRewardsCountRequest, options?: RawAxiosRequestConfig) {
        return MemberServiceInternalControllerApiFp(this.configuration).getClaimedRewardsCount(requestParameters.memberId, requestParameters.from, requestParameters.to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberServiceInternalControllerApiGetClaimedRewardsReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberServiceInternalControllerApi
     */
    public getClaimedRewardsReport(requestParameters: MemberServiceInternalControllerApiGetClaimedRewardsReportRequest, options?: RawAxiosRequestConfig) {
        return MemberServiceInternalControllerApiFp(this.configuration).getClaimedRewardsReport(requestParameters.businessId, requestParameters.rewardId, requestParameters.pageable, requestParameters.from, requestParameters.to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberServiceInternalControllerApiGetConsumerIdByCognitoEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberServiceInternalControllerApi
     */
    public getConsumerIdByCognitoEmail(requestParameters: MemberServiceInternalControllerApiGetConsumerIdByCognitoEmailRequest, options?: RawAxiosRequestConfig) {
        return MemberServiceInternalControllerApiFp(this.configuration).getConsumerIdByCognitoEmail(requestParameters.cognitoEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberServiceInternalControllerApiGetMemberIdByBusinessEntityIdAndConsumerIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberServiceInternalControllerApi
     */
    public getMemberIdByBusinessEntityIdAndConsumerId(requestParameters: MemberServiceInternalControllerApiGetMemberIdByBusinessEntityIdAndConsumerIdRequest, options?: RawAxiosRequestConfig) {
        return MemberServiceInternalControllerApiFp(this.configuration).getMemberIdByBusinessEntityIdAndConsumerId(requestParameters.businessEntityId, requestParameters.consumerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberServiceInternalControllerApiGetMembers1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberServiceInternalControllerApi
     */
    public getMembers1(requestParameters: MemberServiceInternalControllerApiGetMembers1Request = {}, options?: RawAxiosRequestConfig) {
        return MemberServiceInternalControllerApiFp(this.configuration).getMembers1(requestParameters.consumerId, requestParameters.memberIds, requestParameters.businessEntityId, requestParameters.statuses, requestParameters.search, requestParameters.lastSignInFrom, requestParameters.lastSignInTo, requestParameters.ghostAccount, requestParameters.withBalances, requestParameters.pointsSymbol, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberServiceInternalControllerApiGetMembersPagedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberServiceInternalControllerApi
     */
    public getMembersPaged(requestParameters: MemberServiceInternalControllerApiGetMembersPagedRequest = {}, options?: RawAxiosRequestConfig) {
        return MemberServiceInternalControllerApiFp(this.configuration).getMembersPaged(requestParameters.consumerId, requestParameters.memberIds, requestParameters.businessEntityId, requestParameters.statuses, requestParameters.search, requestParameters.lastSignInFrom, requestParameters.lastSignInTo, requestParameters.ghostAccount, requestParameters.withBalances, requestParameters.pointsSymbol, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberServiceInternalControllerApiGetSnapshotByIdAndTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberServiceInternalControllerApi
     */
    public getSnapshotByIdAndType(requestParameters: MemberServiceInternalControllerApiGetSnapshotByIdAndTypeRequest, options?: RawAxiosRequestConfig) {
        return MemberServiceInternalControllerApiFp(this.configuration).getSnapshotByIdAndType(requestParameters.id, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberServiceInternalControllerApiGetTiersUsageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberServiceInternalControllerApi
     */
    public getTiersUsage(requestParameters: MemberServiceInternalControllerApiGetTiersUsageRequest, options?: RawAxiosRequestConfig) {
        return MemberServiceInternalControllerApiFp(this.configuration).getTiersUsage(requestParameters.businessEntityId, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
  * @export
  * @enum {string}
  */
export enum GetCampaignMembersCampaignChannelEnum {
    SMS = 'SMS',
    EMAIL = 'EMAIL'
}
/**
  * @export
  * @enum {string}
  */
export enum GetCampaignMembersStatusesEnum {
    MEMBER = 'MEMBER',
    GUEST = 'GUEST',
    BLOCKED = 'BLOCKED'
}
/**
  * @export
  * @enum {string}
  */
export enum GetCampaignMembersGendersEnum {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    OTHER = 'OTHER'
}
/**
  * @export
  * @enum {string}
  */
export enum GetCampaignMembersCountCampaignChannelEnum {
    SMS = 'SMS',
    EMAIL = 'EMAIL'
}
/**
  * @export
  * @enum {string}
  */
export enum GetCampaignMembersCountStatusesEnum {
    MEMBER = 'MEMBER',
    GUEST = 'GUEST',
    BLOCKED = 'BLOCKED'
}
/**
  * @export
  * @enum {string}
  */
export enum GetCampaignMembersCountGendersEnum {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    OTHER = 'OTHER'
}
/**
  * @export
  * @enum {string}
  */
export enum GetMembers1StatusesEnum {
    ACTIVE = 'ACTIVE',
    BLOCKED = 'BLOCKED',
    DEPRECATED = 'DEPRECATED',
    FORMER = 'FORMER'
}
/**
  * @export
  * @enum {string}
  */
export enum GetMembersPagedStatusesEnum {
    ACTIVE = 'ACTIVE',
    BLOCKED = 'BLOCKED',
    DEPRECATED = 'DEPRECATED',
    FORMER = 'FORMER'
}
/**
  * @export
  * @enum {string}
  */
export enum GetSnapshotByIdAndTypeTypeEnum {
    BUSINESS_ENTITY = 'BUSINESS_ENTITY',
    BUSINESS_USER = 'BUSINESS_USER',
    MEMBER = 'MEMBER',
    OFFER = 'OFFER',
    TIER_CONFIGURATION = 'TIER_CONFIGURATION',
    REFERRAL_PROGRAM = 'REFERRAL_PROGRAM',
    CONSUMER = 'CONSUMER',
    REWARD = 'REWARD'
}
